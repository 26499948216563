<template>
  <x-dialog :proxy="importDialog">
    <div class="mt-3">
      <h3 style="color: #333">第一步：填写导入数据信息</h3>
      <div class="tip">
        <p>请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除。</p>
        <p>表格中的姓名、分组、账户余额可不填。分组需是当前商城已有的分组。</p>
        <p>账号由2~16位汉字、字母、数字组成，可纯汉字、纯字母、纯数字，字母不区分大小写。</p>
        <p>密码由6~16位字母、数字组成，可纯字母、纯数字，字母区分大小写。</p>
      </div>
      <div style="margin-top: 10px;">
        <el-radio-group v-model="selectType" @input="changeType">
          <el-radio :label="1">手机号导入</el-radio>
          <el-radio :label="2">账号密码导入</el-radio>
        </el-radio-group>
      </div>
      <el-button type="text" @click="downTemplate(selectType)">下载模板</el-button>
    </div>
    <div class="mt-3">
      <h3 style="color: #333">第二步：上传填好的信息表</h3>
      <div class="tip">
        文件后缀名必须为 <b>.xls</b> 或 <b>.xlsx</b>（即 <b>Excel</b> 格式），文件大小不得大于10M，最多支持导入3000条数据。
      </div>

      <el-popover placement="top-start" trigger="hover" popper-class="special-reminder">
        <el-alert title="特别提醒" type="warning" description="导入过程中如发现同名客户，且对该客户数据有更新权限，则更新这条客户数据。" :closable="false"
          show-icon />

        <upload-excel ref="uploadexcel" :showFileName="true" type="text" @clearFile="clearFile" :on-success="excelSuccess"
          slot="reference" />
      </el-popover>
    </div>
  </x-dialog>
</template>

<script>
import XDialog from '@/components/x-dialog/x-dialog';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';
import UploadExcel from '@/components/UploadExcel';
import { RegExp as $regExp } from '../../../ui-utils';
import { handleDownload } from '@/utils';
import * as API_Users from '@/api/users';

export default {
  name: 'import-member',
  components: {
    UploadExcel,
    XDialog
  },
  data () {
    return {
      submitImportCardKeysFlag: false,//防止重复提交
      selectType: 1,//表格导入的类型 手机号  账号密码
      importDialog: $xDialog.create({
        title: '批量新导入用户',
        wrapperClass: 'import-member-dialog',
        beforeConfirm: () => this.uploadData(),
      }),
      importData: [],
      importing: false,
      importPercentage: 0,
      templateKey: {
        importMobile: ['member_name', 'mobile', 'group_name', 'enable_money'],
        importAccout: ['member_name', 'login_account', 'password', 'group_name', 'enable_money']
      },
      templateHeader: {
        importMobile: ['用户姓名', '手机号', '分组', '余额'],
        importAccout: ['用户姓名', '账号', '密码', '分组', '余额']
      }
    }
  },
  methods: {
    changeType (data) {
      this.$refs.uploadexcel.clearnUploadFiles();
    },
    clearFile () {
      this.importData = [];
    },
    show () {
      this.importData = [];
      this.importing = false;
      this.selectType = 1;//表格导入的类型 手机号  账号密码
      this.importPercentage = 0;
      this.importDialog.display();
    },
    excelSuccess (fileData) {
      this.importData = [];
      if (this.selectType == 1) {//手机号导入
        if (fileData.header[0] != "用户姓名" || fileData.header[1] != "手机号" || fileData.header[2] != "分组" || fileData.header[3] != "余额" || fileData.header.length != 4) return this.$message.error("文件格式不正确，请重新上传"), this.$refs.uploadexcel.clearnUploadFiles()
        if (fileData.results.length > 3000) return this.$message.error('要导入的表格数据量超过3000条的上限，请重新选择文件上传');
        fileData.results.map((item, index) => {
          this.importData.push({
            member_name: item["用户姓名"] ? item["用户姓名"].toString().trim() : item["手机号"] ? 'm_' + item["手机号"].toString().trim() : '',
            mobile: item["手机号"] ? item["手机号"].toString().trim() : '',
            group_name: item["分组"] ? item["分组"].toString().trim() : '',
            enable_money: item["余额"] ? Number(item["余额"].toString().trim()) : 0
          });
        })
      } else {//账户密码导入
        if (fileData.header[0] != "用户姓名" || fileData.header[1] != "账号" || fileData.header[2] != "密码" || fileData.header[3] != "分组" || fileData.header[4] != "余额" || fileData.header.length != 5) return this.$message.error("文件格式不正确，请重新上传"), this.$refs.uploadexcel.clearnUploadFiles()
        if (fileData.results.length > 3000) return this.$message.error('要导入的表格数据量超过3000条的上限，请重新选择文件上传');
        fileData.results.map((item, index) => {
          this.importData.push({
            member_name: item["用户姓名"] ? item["用户姓名"].toString().trim() : item["账号"] ? item["账号"].toString().trim() : '',
            login_account: item["账号"] ? item["账号"].toString().trim() : '',
            password: item["密码"] ? item["密码"].toString().trim() : '',
            group_name: item["分组"] ? item["分组"].toString().trim() : '',
            enable_money: item["余额"] ? Number(item["余额"].toString().trim()) : 0
          });
        })
      }
    },
    uploadData () {
      return new Promise(resolve => {
        if (this.submitImportCardKeysFlag) return
        this.submitImportCardKeysFlag = true
        if (!this.importData.length) {
          this.$message.error('请导入数据');
          resolve(false);
        } else {
          API_Users.importMember({
            member_shop: this.importData,
            type: this.selectType == 1 ? 0 : 1
          }).then((res) => {
            this.submitImportCardKeysFlag = false
            // let alert_content = `本次表格导入数据共${this.importData.length}条, 其中成功${res.success}条, 失败${res.false}条`

            if (res.file && res.file != '') {
              this.$alert(res.message, '提示', {
                confirmButtonText: '下载失败数据'
              }).then(() => {
                window.location.href = res.file
              }).catch(() => { })
              resolve(true);
            } else {
              this.$alert(res.message, '提示').then(() => { }).catch(() => { })
              resolve(true);
            }
            this.$emit('imported');
          });
        }
      });
    },
    downTemplate (type) {
      let templateMobile = {//手机号导入
        member_name: '',
        mobile: '',
        group_name: '',
        enable_money: ''
      },
        templateAccout = {//账户密码导入
          member_name: '',
          login_account: '',
          password: '',
          group_name: '',
          enable_money: '',
        };
      handleDownload(
        [
          type == 1 ? templateMobile : templateAccout
        ],
        type == 1 ? this.templateHeader.importMobile : this.templateHeader.importAccout,
        type == 1 ? this.templateKey.importMobile : this.templateKey.importAccout,
        '新用户批量导入模板'
      );
    },
  }
}
</script>

<style lang="scss">
.special-reminder {
  padding: 0;

  .el-alert {
    padding: 16px;
  }
}

.import-member-dialog {
  .tip {
    font-size: 13px;
    color: #666;

    p {
      margin: 0;
      padding: 0;
    }
  }
}
</style>
